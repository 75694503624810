:root {
  --deepmaroon: #8f3013;
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

body {
  font-family: 'Arial', sans-serif;
  background: #fafaf4;
  color: var(--deepmaroon);
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
}
/* .container {
  text-align: center;
  flex-direction: column;
  width: 100%;
  max-width: 1140px;
  padding-top: 200px;
} */

.title {
  font-size: 64px;
  margin: 20px;
  color: var(--deepmaroon);
  font-family: Pt Serif, serif;
  font-weight: 900;
  line-height: 80px;
}
/* .subtitle {
  font-size: 18px;
  font-family: Montserrat, sans-serif;
  color: var(--deepmaroon);
  margin-bottom: 50px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 3px;
} */
.mountains {
  max-width: 100%;
  object-fit: fill;
  margin-top: auto;
  margin-bottom: 20px;
}
.podcast-description {
  font-size: 16px;
  color: #666;
  margin-top: 20px;
  font-family: Montserrat, sans-serif;
  font-style: italic;
}
.icon-container {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 25px;
}

.icon, .icon:link, .icon:visited {
  color: var(--deepmaroon);
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 10px;
}

.icon:hover {
  color: red;
}
